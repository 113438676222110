import LayoutFillImg from "../../../Misc/LayoutFillImg/LayoutFillImg";
import { useRouter } from "next/router";

import styles from "./ResultsShowcase.module.scss";
import Button from "../../../Misc/button/Button";
const ResultsShowcase = () => {
  const router = useRouter();
  return (
    <section className={styles.creativeContainer}>
      <div className={styles.creativeSubContainer}>
        <h2 className={styles.creativeHeading}>
          Design Gallery - An Assortment of our AI Designs
        </h2>
        <h4 className={styles.creativesubHeading}>
          Unlock creative possibilities{" "}
        </h4>
        <p className={styles.creativeDisc}>
          Our AI redesigns any space through evaluating architectural elements,
          detecting the desired area, understanding preferred design styles and
          adhering to your color preferences & text instructions.
        </p>
        <div className={styles.creativeBtns}>
          <Button
            variant="primary"
            onClick={() => router.push("/business/get-access")}
            className={styles.creativePrimaryBtn}
          >
            Get Contractor Access
          </Button>
          <Button
            variant="secondary"
            onClick={() => router.push("/free/free-editor")}
            className={styles.creativeSecondaryBtn}
          >
            Try For Free
          </Button>
        </div>
      </div>

      <div className={styles.creativeBoxContainer}>
        {/* Room Box 1 */}
        <div className={styles.creativeBox}>
          <LayoutFillImg src="https://res.cloudinary.com/dxqnocwzs/image/upload/v1737061530/userGeneratedImages/lqzi1xamind4vivkfg9b.png" alt="creative1" />

          <div className={styles.creativeBoxData}>
            <h3 className={styles.creativeBoxheading}>Used inputs</h3>
            <ul className={styles.creativeBoxList}>
              <li className={styles.creativeBoxListItem}>Pergola</li>
              <li className={styles.creativeBoxListItem}>Pavers</li>
              <li className={styles.creativeBoxListItem}>Manicured Hedges</li>
              <li className={styles.creativeBoxListItem}>Outdoor Furniture</li>
         
            </ul>
          </div>
        </div>

        {/* Room Box 2 */}
        <div className={styles.creativeBox}>
          <LayoutFillImg src="https://res.cloudinary.com/dxqnocwzs/image/upload/v1737057934/userGeneratedImages/ljjitvazdk3b9ee5tkjd.png" alt="creative2" />

          <div className={styles.creativeBoxData}>
            <h3 className={styles.creativeBoxheading}>Used inputs</h3>
            <ul className={styles.creativeBoxList}>
              <li className={styles.creativeBoxListItem}>Pavers</li>
              <li className={styles.creativeBoxListItem}>Swimming pool</li>
              <li className={styles.creativeBoxListItem}>Natural grass</li>
              <li className={styles.creativeBoxListItem}>Manicured Hedges</li>
            </ul>
          </div>
        </div>

        {/* Room Box 3 */}
        <div className={styles.creativeBox}>
          <LayoutFillImg src="https://res.cloudinary.com/dxqnocwzs/image/upload/v1737054822/userGeneratedImages/mnfhejxwzwnxa5osgvaj.png" alt="creative3" />

          <div className={styles.creativeBoxData}>
            <h3 className={styles.creativeBoxheading}>Used inputs</h3>
            <ul className={styles.creativeBoxList}>
              <li className={styles.creativeBoxListItem}>Pergola</li>
              <li className={styles.creativeBoxListItem}>Pavers</li>
              <li className={styles.creativeBoxListItem}>Outdoor Furniture</li>
            </ul>
          </div>
        </div>

        {/* Room Box 4 */}
        <div className={styles.creativeBox}>
          <LayoutFillImg src="https://res.cloudinary.com/dxqnocwzs/image/upload/v1736821906/userGeneratedImages/zwvtinpl7dt4ipbvjxk2.png" alt="creative4" />

          <div className={styles.creativeBoxData}>
            <h3 className={styles.creativeBoxheading}>Used inputs</h3>
            <ul className={styles.creativeBoxList}>
              <li className={styles.creativeBoxListItem}>BBQ Island</li>
              <li className={styles.creativeBoxListItem}>Pavers</li>
              <li className={styles.creativeBoxListItem}>Bark</li>
              <li className={styles.creativeBoxListItem}>Desert Plants</li>
            </ul>
          </div>
        </div>

        {/* Room Box 5 */}
        <div className={styles.creativeBox}>
          <LayoutFillImg src="https://res.cloudinary.com/dxqnocwzs/image/upload/v1736820539/userGeneratedImages/z15xngvcdrnzuakb6ymw.png" alt="creative5" />

          <div className={styles.creativeBoxData}>
            <h3 className={styles.creativeBoxheading}>Used inputs</h3>
            <ul className={styles.creativeBoxList}>
              <li className={styles.creativeBoxListItem}>Pavers</li>
              <li className={styles.creativeBoxListItem}>Pergola</li>
              <li className={styles.creativeBoxListItem}>Swimming Pool</li>
              <li className={styles.creativeBoxListItem}>Potted Plants</li>
            </ul>
          </div>
        </div>

        {/* Room Box 6 */}
        <div className={styles.creativeBox}>
          <LayoutFillImg src="https://res.cloudinary.com/dxqnocwzs/image/upload/v1736818948/userGeneratedImages/com4kfmzkvow1zlabjwm.png" alt="creative6" />

          <div className={styles.creativeBoxData}>
            <h3 className={styles.creativeBoxheading}>Used inputs</h3>
            <ul className={styles.creativeBoxList}>
              <li className={styles.creativeBoxListItem}>Sports bar</li>
              <li className={styles.creativeBoxListItem}>Pergola</li>
              <li className={styles.creativeBoxListItem}>Swimming Pool</li>
              <li className={styles.creativeBoxListItem}>Potted Plants</li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ResultsShowcase;
